"use client";
import Tooltip from "@clayton-homes/home-designer/components/tooltip";

export interface PinPointData {
	title: string;
	text: string;
	className: string;
}

export default function PinPoint({ pinPoint }: { pinPoint: PinPointData }) {
	return (
		<Tooltip>
			<Tooltip.Trigger
				aria-label={`${pinPoint.title} tooltip`}
				className={`${pinPoint.className} absolute flex h-10 w-10 animate-pulse items-center justify-center rounded-full border border-white`}
			>
				<div className="h-6 w-6 rounded-full bg-white" />
			</Tooltip.Trigger>
			<Tooltip.Content className="w-64 border border-white bg-white fill-white p-4 text-black">
				<h6 className="font-semibold uppercase text-primary-500">
					{pinPoint.title}
				</h6>
				{pinPoint.text}
			</Tooltip.Content>
		</Tooltip>
	);
}
